import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49127cdc = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "" */))
const _6fe4e503 = () => interopDefault(import('../pages/login/login.vue' /* webpackChunkName: "" */))
const _1f0f5692 = () => interopDefault(import('../pages/login/forgotPwd/index.vue' /* webpackChunkName: "" */))
const _27519d6c = () => interopDefault(import('../pages/login/changePwd/index.vue' /* webpackChunkName: "" */))
const _7575e072 = () => interopDefault(import('../pages/login/resetPwd/index.vue' /* webpackChunkName: "" */))
const _06a042f1 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "" */))
const _65bb2ac2 = () => interopDefault(import('../pages/category/detail.vue' /* webpackChunkName: "" */))
const _eada6a60 = () => interopDefault(import('../pages/empty/index.vue' /* webpackChunkName: "" */))
const _7e5e65f2 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "" */))
const _cfbc188a = () => interopDefault(import('../pages/product/recently/index.vue' /* webpackChunkName: "" */))
const _562b5dcb = () => interopDefault(import('../pages/bag/index.vue' /* webpackChunkName: "" */))
const _95299ff4 = () => interopDefault(import('../pages/mine/index.vue' /* webpackChunkName: "" */))
const _7815f38d = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "" */))
const _0647b38e = () => interopDefault(import('../pages/member/point/index.vue' /* webpackChunkName: "" */))
const _360850d6 = () => interopDefault(import('../pages/member/birthday/index.vue' /* webpackChunkName: "" */))
const _2a3e74f7 = () => interopDefault(import('../pages/search/preSearch/index.vue' /* webpackChunkName: "" */))
const _32f9d51b = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "" */))
const _6d3b635a = () => interopDefault(import('../pages/wish/index.vue' /* webpackChunkName: "" */))
const _7f0d8556 = () => interopDefault(import('../pages/pindex/index.vue' /* webpackChunkName: "" */))
const _652ed6f2 = () => interopDefault(import('../pages/sindex/index.vue' /* webpackChunkName: "" */))
const _2bae1a92 = () => interopDefault(import('../pages/address/index.vue' /* webpackChunkName: "" */))
const _97d3536e = () => interopDefault(import('../pages/address/list.vue' /* webpackChunkName: "" */))
const _7aa800de = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "" */))
const _3c762d72 = () => interopDefault(import('../pages/order/list/index.vue' /* webpackChunkName: "" */))
const _4e8431b6 = () => interopDefault(import('../pages/order/detail/index.vue' /* webpackChunkName: "" */))
const _bc6a9888 = () => interopDefault(import('../pages/order/search/index.vue' /* webpackChunkName: "" */))
const _d5507c24 = () => interopDefault(import('../pages/paySuccess/index.vue' /* webpackChunkName: "" */))
const _4e76358a = () => interopDefault(import('../pages/cardPay/index.vue' /* webpackChunkName: "" */))
const _8a6a26b4 = () => interopDefault(import('../pages/paypal/index.vue' /* webpackChunkName: "" */))
const _497b14b6 = () => interopDefault(import('../pages/set/index.vue' /* webpackChunkName: "" */))
const _cad57f8a = () => interopDefault(import('../pages/address/country.vue' /* webpackChunkName: "" */))
const _77194169 = () => interopDefault(import('../pages/address/countryAddress.vue' /* webpackChunkName: "" */))
const _0d754cd0 = () => interopDefault(import('../pages/langPage/index.vue' /* webpackChunkName: "" */))
const _09820b78 = () => interopDefault(import('../pages/currency/index.vue' /* webpackChunkName: "" */))
const _45f2b025 = () => interopDefault(import('../pages/policy/index.vue' /* webpackChunkName: "" */))
const _3a65983a = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "" */))
const _6f4c11be = () => interopDefault(import('../pages/policy/aboutUs/index.vue' /* webpackChunkName: "" */))
const _3b089df6 = () => interopDefault(import('../pages/policy/helpCenter/index.vue' /* webpackChunkName: "" */))
const _1e8aeb89 = () => interopDefault(import('../pages/policy/helpSearch/index.vue' /* webpackChunkName: "" */))
const _3c3c008b = () => interopDefault(import('../pages/policy/helpArticle/index.vue' /* webpackChunkName: "" */))
const _7af4a505 = () => interopDefault(import('../pages/policy/feedback/index.vue' /* webpackChunkName: "" */))
const _d2705d64 = () => interopDefault(import('../pages/track/index.vue' /* webpackChunkName: "" */))
const _3e6bc219 = () => interopDefault(import('../pages/coupon/index.vue' /* webpackChunkName: "" */))
const _264ee19a = () => interopDefault(import('../pages/coupon/couponProduct/index.vue' /* webpackChunkName: "" */))
const _3d44dd2e = () => interopDefault(import('../pages/coupon/couponChoose/index.vue' /* webpackChunkName: "" */))
const _8a747b5a = () => interopDefault(import('../pages/coupon/couponDiscount/index.vue' /* webpackChunkName: "" */))
const _7964d22c = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "" */))
const _cb4e1dd8 = () => interopDefault(import('../pages/wallet/explain/index.vue' /* webpackChunkName: "" */))
const _2830abdc = () => interopDefault(import('../pages/creditCard/index.vue' /* webpackChunkName: "" */))
const _f41d1560 = () => interopDefault(import('../pages/influencer/index.vue' /* webpackChunkName: "" */))
const _2dd702d2 = () => interopDefault(import('../pages/influencer/faq/index.vue' /* webpackChunkName: "" */))
const _a982e6f0 = () => interopDefault(import('../pages/influencer/mine/index.vue' /* webpackChunkName: "" */))
const _f1779962 = () => interopDefault(import('../pages/influencer/order/index.vue' /* webpackChunkName: "" */))
const _fa4d96ea = () => interopDefault(import('../pages/influencer/earning/index.vue' /* webpackChunkName: "" */))
const _71545e51 = () => interopDefault(import('../pages/influencer/setting/index.vue' /* webpackChunkName: "" */))
const _7574f5d8 = () => interopDefault(import('../pages/influencer/register/index.vue' /* webpackChunkName: "" */))
const _6d0ebc0a = () => interopDefault(import('../pages/influencer/verifying/index.vue' /* webpackChunkName: "" */))
const _22b65da2 = () => interopDefault(import('../pages/influencer/code/index.vue' /* webpackChunkName: "" */))
const _5b86321a = () => interopDefault(import('../pages/review/personal/index.vue' /* webpackChunkName: "" */))
const _10dc0638 = () => interopDefault(import('../pages/review/edit/index.vue' /* webpackChunkName: "" */))
const _09d8b2aa = () => interopDefault(import('../pages/review/detail/index.vue' /* webpackChunkName: "" */))
const _e2c59282 = () => interopDefault(import('../pages/review/success/index.vue' /* webpackChunkName: "" */))
const _5f4ef04a = () => interopDefault(import('../pages/review/share/index.vue' /* webpackChunkName: "" */))
const _3ec3b4a2 = () => interopDefault(import('../pages/review/center/index.vue' /* webpackChunkName: "" */))
const _4eeee956 = () => interopDefault(import('../pages/activity/monthlyNew/index.vue' /* webpackChunkName: "" */))
const _74558cf4 = () => interopDefault(import('../pages/activity/bestSeller/index.vue' /* webpackChunkName: "" */))
const _8cd9211e = () => interopDefault(import('../pages/activity/newComer/index.vue' /* webpackChunkName: "" */))
const _145c3bf3 = () => interopDefault(import('../pages/activity/fullFold/index.vue' /* webpackChunkName: "" */))
const _2cd14158 = () => interopDefault(import('../pages/activity/fullReduce/index.vue' /* webpackChunkName: "" */))
const _62383a0a = () => interopDefault(import('../pages/activity/flashSale/index.vue' /* webpackChunkName: "" */))
const _2907cb5f = () => interopDefault(import('../pages/activity/freegift/index.vue' /* webpackChunkName: "" */))
const _7e58acb8 = () => interopDefault(import('../pages/activity/tmpl/index.vue' /* webpackChunkName: "" */))
const _2e35e67c = () => interopDefault(import('../pages/forbidden/index.vue' /* webpackChunkName: "" */))
const _d3851d5a = () => interopDefault(import('../pages/appPage/productSecurity/index.vue' /* webpackChunkName: "" */))
const _103c4e58 = () => interopDefault(import('../pages/appPage/productSize/index.vue' /* webpackChunkName: "" */))
const _b339b37e = () => interopDefault(import('../pages/appPage/productShipping/index.vue' /* webpackChunkName: "" */))
const _73d56c94 = () => interopDefault(import('../pages/appPage/productProp/index.vue' /* webpackChunkName: "" */))
const _d5891884 = () => interopDefault(import('../pages/payTransit/index.vue' /* webpackChunkName: "" */))
const _3d4d265f = () => interopDefault(import('../pages/promoCode/index.vue' /* webpackChunkName: "" */))
const _3d01c4a2 = () => interopDefault(import('../pages/edmSubscribe/index.vue' /* webpackChunkName: "" */))
const _c063fba8 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "" */))
const _3c618843 = () => interopDefault(import('../pages/custonIndex/index.vue' /* webpackChunkName: "" */))
const _efe5cb4c = () => interopDefault(import('../pages/sustainability/index.vue' /* webpackChunkName: "" */))
const _9c98ac54 = () => interopDefault(import('../pages/exchange/index.vue' /* webpackChunkName: "" */))
const _f2f79260 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "" */))
const _9abeedde = () => interopDefault(import('../pages/affiliate/affiliateLogin/index.vue' /* webpackChunkName: "" */))
const _c230d62c = () => interopDefault(import('../pages/affiliate/affiliateCreate/index.vue' /* webpackChunkName: "" */))
const _3f2580d6 = () => interopDefault(import('../pages/affiliate/affiliateAccount/index.vue' /* webpackChunkName: "" */))
const _779bef40 = () => interopDefault(import('../pages/affiliate/affiliateStatus/index.vue' /* webpackChunkName: "" */))
const _2f3d1c1c = () => interopDefault(import('../pages/affiliate/dropShipping/index.vue' /* webpackChunkName: "" */))
const _e636fb9e = () => interopDefault(import('../pages/affiliate/dropShippingAccount/index.vue' /* webpackChunkName: "" */))
const _f20b185a = () => interopDefault(import('../pages/giftCard/index.vue' /* webpackChunkName: "" */))
const _508b8b40 = () => interopDefault(import('../pages/giftCard/Balance/index.vue' /* webpackChunkName: "" */))
const _cd855798 = () => interopDefault(import('../pages/giftCard/Application/index.vue' /* webpackChunkName: "" */))
const _57cfb126 = () => interopDefault(import('../pages/template/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _49127cdc,
    name: "Home"
  }, {
    path: "/index",
    component: _49127cdc,
    name: "Home-Index"
  }, {
    path: "/index.htm",
    component: _49127cdc,
    name: "Home-Old"
  }, {
    path: "/login",
    component: _6fe4e503,
    name: "Login"
  }, {
    path: "/login.htm",
    component: _6fe4e503,
    name: "Login-Old"
  }, {
    path: "/forgot-pwd",
    component: _1f0f5692,
    name: "ForgotPwd"
  }, {
    path: "/change-pwd",
    component: _27519d6c,
    name: "ChangePwd"
  }, {
    path: "/reset-pwd",
    component: _7575e072,
    name: "ResetPwd"
  }, {
    path: "/category",
    component: _06a042f1,
    name: "Category"
  }, {
    path: "/collection",
    component: _06a042f1,
    name: "Category-Seo"
  }, {
    path: "/category.htm",
    component: _06a042f1,
    name: "Category-Old"
  }, {
    path: "/category/:code/:seoUrl",
    component: _65bb2ac2,
    name: "CategoryDetail"
  }, {
    path: "/category/:seoUrl",
    component: _65bb2ac2,
    name: "CategoryDetail-Old"
  }, {
    path: "/collection/:seoUrl",
    component: _65bb2ac2,
    name: "CategoryDetail-Seo"
  }, {
    path: "/item/:code/:seoUrl(.*)",
    component: _eada6a60,
    name: "Product"
  }, {
    path: "/product/:seoUrl",
    component: _7e5e65f2,
    name: "Product-Old"
  }, {
    path: "/recently-viewed",
    component: _cfbc188a,
    name: "ProductRecently"
  }, {
    path: "/shoppingbag",
    component: _562b5dcb,
    name: "Bag"
  }, {
    path: "/shoppingbag.htm",
    component: _562b5dcb,
    name: "Bag-Old"
  }, {
    path: "/mine",
    component: _95299ff4,
    name: "Mine"
  }, {
    path: "/mine",
    component: _95299ff4,
    name: "Mine-Old"
  }, {
    path: "/member/center",
    component: _7815f38d,
    name: "MemberCenter"
  }, {
    path: "/member/point",
    component: _0647b38e,
    name: "MemberPoint"
  }, {
    path: "/member/birthday",
    component: _360850d6,
    name: "MemberBirthday"
  }, {
    path: "/presearch",
    component: _2a3e74f7,
    name: "PreSearch"
  }, {
    path: "/search",
    component: _32f9d51b,
    name: "Search"
  }, {
    path: "/search.htm",
    component: _32f9d51b,
    name: "Search-Old"
  }, {
    path: "/wishlist",
    component: _6d3b635a,
    name: "Wish"
  }, {
    path: "/pindex",
    component: _7f0d8556,
    name: "Pindex"
  }, {
    path: "/sindex",
    component: _652ed6f2,
    name: "Sindex"
  }, {
    path: "/addLocation",
    component: _2bae1a92,
    name: "Address"
  }, {
    path: "/address",
    component: _97d3536e,
    name: "AddressList"
  }, {
    path: "/orderconfirm",
    component: _7aa800de,
    name: "OrderConfirm"
  }, {
    path: "/order-list",
    component: _3c762d72,
    name: "OrderList"
  }, {
    path: "/order-detail",
    component: _4e8431b6,
    name: "OrderDetail"
  }, {
    path: "/orderdetail.htm",
    component: _4e8431b6,
    name: "OrderDetail-Old"
  }, {
    path: "/order-search",
    component: _bc6a9888,
    name: "OrderSearch"
  }, {
    path: "/paysuccess",
    component: _d5507c24,
    name: "PaySuccess"
  }, {
    path: "/paysuccess.htm",
    component: _d5507c24,
    name: "PaySuccess-Old"
  }, {
    path: "/cardpay",
    component: _4e76358a,
    name: "CardPay"
  }, {
    path: "/paypal",
    component: _8a6a26b4,
    name: "Paypal"
  }, {
    path: "/setting",
    component: _497b14b6,
    name: "Setting"
  }, {
    path: "/country",
    component: _cad57f8a,
    name: "Country"
  }, {
    path: "/countryAddress",
    component: _77194169,
    name: "CountryAddress"
  }, {
    path: "/lang",
    component: _0d754cd0,
    name: "LangPage"
  }, {
    path: "/currency",
    component: _09820b78,
    name: "Currency"
  }, {
    path: "/policy",
    component: _45f2b025,
    name: "Policy"
  }, {
    path: "/policy/contact-us",
    component: _3a65983a,
    name: "ContactUs"
  }, {
    path: "/policy/:code(.*)",
    component: _45f2b025,
    name: "PolicyOld"
  }, {
    path: "/about-us",
    component: _6f4c11be,
    name: "AboutUs"
  }, {
    path: "/help-center",
    component: _3b089df6,
    name: "HelpCenter"
  }, {
    path: "/help-search",
    component: _1e8aeb89,
    name: "HelpSearch"
  }, {
    path: "/help-center/article/:id",
    component: _3c3c008b,
    name: "HelpArticle"
  }, {
    path: "/feedback",
    component: _7af4a505,
    name: "Feedback"
  }, {
    path: "/track-search",
    component: _d2705d64,
    name: "TrackSearch"
  }, {
    path: "/track-search.htm",
    component: _d2705d64,
    name: "TrackSearch-Old"
  }, {
    path: "/coupon",
    component: _3e6bc219,
    name: "Coupon"
  }, {
    path: "/couponProList",
    component: _264ee19a,
    name: "CouponProduct"
  }, {
    path: "/coupon-choose",
    component: _3d44dd2e,
    name: "CouponChoose"
  }, {
    path: "/discounts",
    component: _8a747b5a,
    name: "CouponDiscount"
  }, {
    path: "/wallet",
    component: _7964d22c,
    name: "Wallet"
  }, {
    path: "/wallet/rule",
    component: _cb4e1dd8,
    name: "WalletExplain"
  }, {
    path: "/creditcard",
    component: _2830abdc,
    name: "CreditCard"
  }, {
    path: "/influencer",
    component: _f41d1560,
    name: "Influencer-Index"
  }, {
    path: "/influencer.htm",
    component: _f41d1560,
    name: "Influencer-Index-Old"
  }, {
    path: "/influencer/faq",
    component: _2dd702d2,
    name: "Influencer-Faq"
  }, {
    path: "/influencer/home",
    component: _a982e6f0,
    name: "Influencer-Mine"
  }, {
    path: "/influencer/order",
    component: _f1779962,
    name: "Influencer-Order"
  }, {
    path: "/influencer/earning",
    component: _fa4d96ea,
    name: "Influencer-Earning"
  }, {
    path: "/influencer/setting",
    component: _71545e51,
    name: "Influencer-Setting"
  }, {
    path: "/influencer/register",
    component: _7574f5d8,
    name: "Influencer-Register"
  }, {
    path: "/influencer/verifying",
    component: _6d0ebc0a,
    name: "Influencer-Verifying"
  }, {
    path: "/influencer/code",
    component: _22b65da2,
    name: "Influencer-Code"
  }, {
    path: "/review-personal",
    component: _5b86321a,
    name: "Review-Personal"
  }, {
    path: "/review-edit",
    component: _10dc0638,
    name: "Review-Edit"
  }, {
    path: "/review-detail",
    component: _09d8b2aa,
    name: "Review-Detail"
  }, {
    path: "/review-success",
    component: _e2c59282,
    name: "Review-Success"
  }, {
    path: "/comment/share",
    component: _5f4ef04a,
    name: "Review-Share"
  }, {
    path: "/reviews",
    component: _3ec3b4a2,
    name: "Review-Center"
  }, {
    path: "/activity/monthly-new",
    component: _4eeee956,
    name: "Activity-MonthlyNew"
  }, {
    path: "/activity/motherDay.htm",
    component: _4eeee956,
    name: "Activity-MonthlyNew-Old"
  }, {
    path: "/activity/best-seller",
    component: _74558cf4,
    name: "Activity-BestSeller"
  }, {
    path: "/activity/Best-Seller.htm",
    component: _74558cf4,
    name: "Activity-BestSeller-Old"
  }, {
    path: "/activity/newcomer",
    component: _8cd9211e,
    name: "Activity-NewComer"
  }, {
    path: "/activity/buy-more-save-more.htm",
    component: _145c3bf3,
    name: "Activity-FullFold-Old"
  }, {
    path: "/activity/full-reduce.htm",
    component: _2cd14158,
    name: "Activity-FullReduce-Old"
  }, {
    path: "/activity/FlashSale.htm",
    component: _62383a0a,
    name: "Activity-FlashSale-Old"
  }, {
    path: "/activity/freegifts.htm",
    component: _2907cb5f,
    name: "Activity-FreeGifts-Old"
  }, {
    path: "/activity/:seoUrl",
    component: _7e58acb8,
    name: "Activity-Tmpl"
  }, {
    path: "/activity/buy-more-save-more",
    component: _145c3bf3,
    name: "Activity-FullFold"
  }, {
    path: "/activity/full-reduce",
    component: _2cd14158,
    name: "Activity-FullReduce"
  }, {
    path: "/activity/full-gift",
    component: _2907cb5f,
    name: "Activity-FreeGifts"
  }, {
    path: "/activity/flash-sale",
    component: _62383a0a,
    name: "Activity-FlashSale"
  }, {
    path: "/system-updating",
    component: _2e35e67c,
    name: "SystemUpdating"
  }, {
    path: "/403",
    component: _2e35e67c,
    name: "SystemUpdating-Empty"
  }, {
    path: "/app/product-security",
    component: _d3851d5a,
    name: "App-ProductSecurity"
  }, {
    path: "/app/product-size",
    component: _103c4e58,
    name: "App-ProductSize"
  }, {
    path: "/app/product-shipping",
    component: _b339b37e,
    name: "App-ProductShipping"
  }, {
    path: "/app/product-prop",
    component: _73d56c94,
    name: "App-ProductProp"
  }, {
    path: "/pay-transit",
    component: _d5891884,
    name: "PayTransit"
  }, {
    path: "/promocode",
    component: _3d4d265f,
    name: "PromoCode"
  }, {
    path: "/edm-unsubscribe",
    component: _3d01c4a2,
    name: "EdmSubscribe"
  }, {
    path: "/sitemap",
    component: _eada6a60,
    name: "Sitemap"
  }, {
    path: "/profile",
    component: _c063fba8,
    name: "Profile"
  }, {
    path: "/custom",
    component: _3c618843,
    name: "Custom"
  }, {
    path: "/sustainability",
    component: _efe5cb4c,
    name: "Sustainability"
  }, {
    path: "/jewelry-exchange",
    component: _9c98ac54,
    name: "Exchange"
  }, {
    path: "/affiliate",
    component: _f2f79260,
    name: "Affiliate"
  }, {
    path: "/affiliate/login",
    component: _9abeedde,
    name: "AffiliateLogin"
  }, {
    path: "/affiliate/create",
    component: _c230d62c,
    name: "AffiliateCreate"
  }, {
    path: "/affiliate/account",
    component: _3f2580d6,
    name: "AffiliateAccount"
  }, {
    path: "/affiliate/status",
    component: _779bef40,
    name: "AffiliateStatus"
  }, {
    path: "/drop-shipping",
    component: _2f3d1c1c,
    name: "DropShipping"
  }, {
    path: "/drop-shipping/account",
    component: _e636fb9e,
    name: "DropShippingAccount"
  }, {
    path: "/e-gift-card",
    component: _f20b185a,
    name: "GiftCard"
  }, {
    path: "/e-gift-card-balance",
    component: _508b8b40,
    name: "GiftCardBalance"
  }, {
    path: "/e-gift-card-application",
    component: _cd855798,
    name: "GiftCardApplication"
  }, {
    path: "/pages/:url",
    component: _57cfb126,
    name: "Template-Index"
  }, {
    path: "/guide/:url",
    component: _57cfb126,
    name: "Template-Seo"
  }, {
    path: "/404",
    component: _eada6a60,
    name: "Empty"
  }, {
    path: "/:seoUrl",
    component: _7e5e65f2,
    name: "Product-Seo"
  }, {
    path: "/sitemap/:seoUrl",
    component: _eada6a60,
    name: "SitemapListOnly"
  }, {
    path: "/:seoUrlOne/:seoUrlTwo",
    component: _eada6a60,
    name: "SitemapList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
